.weather-icon {
  max-width: 12.5rem;
  max-height: 12.5rem;
}

.weather-degree {
  top: 0.75rem;
  position: absolute;
}

.weather-type {
  top: 0.75rem;
  position: absolute;
}
