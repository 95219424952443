/* ./src/index.css */

@font-face {
  font-family: GraphikBlack;
  src: url('./core/fonts/GraphikBlack.otf') format('opentype');
}

@font-face {
  font-family: GraphikMedium;
  src: url('./core/fonts/GraphikMedium.otf') format('opentype');
}

@font-face {
  font-family: Graphik;
  src: url('./core/fonts/GraphikRegular.otf') format('opentype');
}

@tailwind base;
@layer base {
  a {
    @apply text-blue-600 underline;
  }
  p,
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-body;
  }
}

@tailwind components;
@tailwind utilities;
