.flag {
  width: 11.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.flag-image-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag-image-wrapper {
  margin-bottom: 0.3125rem;
  position: relative;
}

.flag-image {
  height: 5rem;
  border-radius: 0.5rem;
  margin: 0.1875rem;
}

.flag--selected .flag-image-wrapper::after {
  position: absolute;
  content: '';
  top: -0.25rem;
  left: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  border: 0.1875rem solid #20b272;
  border-radius: 0.75rem;
}

.flag-label {
  flex: 0;
  text-align: center;
  font-weight: bold;
}
