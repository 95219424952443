.iconAlgemeen {
  color: red;
}

.iconExtra {
  color: #dd5b4a;
}

.iconWeather {
  color: #ff1593;
}

.iconDayparts {
  color: #6e30fe;
}

.iconClock {
  color: #20b272;
}

.iconTheme {
  color: #7ad4e5;
}
