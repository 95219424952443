.setup-button-prev__position {
  position: absolute;
  bottom: 1.375rem;
  left: 1.5625rem;
}

.setup-button-next__position {
  position: absolute;
  bottom: 1.375rem;
  right: 1.5625rem;
}
