.image-day-activity {
  max-height: 6.25rem;
  width: 6.25rem;
}

@media only screen and (max-width: 64rem) {
  .image-day-activity {
    height: 5rem;
    width: 5rem;
  }
}
