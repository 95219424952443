.wifi-setup {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.wifi-setup > div {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

.wifi-setup .image-wrapper {
  position: relative;
}

.wifi-setup .image-wrapper img {
  position: relative;
  right: -6.25rem;
}
