.clock-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
