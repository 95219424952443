.setup-header__icons a::before {
  position: absolute;
  content: '';
  height: 0.125rem;
  width: 4.6875rem;
  background-color: #1e83d8;
  top: 50%;
  right: -4.6875rem;
}

.setup-header__icons a::after {
  position: absolute;
  content: '';
  height: 0.125rem;
  width: 4.6875rem;
  background-color: #1e83d8;
  top: 50%;
  left: -4.6875rem;
}

.setup-header__icons a:first-child::after {
  display: none;
}

.setup-header__icons a:last-child::before {
  display: none;
}
