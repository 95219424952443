.link {
  text-decoration: none;
}

.icon__general {
  color: red;
}

.icon__extra {
  color: #dd5b4a;
}

.icon__weather {
  color: #ff1593;
}

.icon__dayparts {
  color: #6e30fe;
}

.icon__clock {
  color: #20b272;
}

.icon__themes {
  color: #7ad4e5;
}

.icon-active {
  color: white;
}
