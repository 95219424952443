.btn-blue {
  position: relative;
  background-color: #1e83d8;
  border: 0.0625rem solid #1e83d8;
  color: #fff;
  border-radius: 0.4375rem;
  text-transform: uppercase;
  font-size: 0.9375rem;
  padding: 1.125rem 1.625rem 1.625rem 1.625rem;
  font-weight: bold;
  z-index: 100;
}

.btn-blue.btn-blue--after {
  padding: 1.125rem 2.8125rem 1rem 1.625rem;
}

.btn-blue.btn-blue--after::after {
  position: absolute;
  content: '';
  right: 1.25rem;
  top: calc(50% - 0.5rem);
  width: 1rem;
  height: 1rem;
  color: #ffffff;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="%23ffffff">  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" /></svg>');
  background-repeat: no-repeat;
}

.btn-blue--outline {
  background-color: transparent;
  padding: 1.125rem 1.625rem 1rem 1.625rem;
  color: #1e83d8;
}

.btn-blue--outline.btn-blue--after {
  padding: 1.125rem 1.625rem 1rem 2.8125rem;
}

.btn-blue--outline.btn-blue--after::after {
  position: absolute;
  content: '';
  left: 1.25rem;
  top: calc(50% - 0.5rem);
  width: 1rem;
  height: 1rem;
  color: #1e83d8;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="%231e83d8"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /></svg>');
  background-repeat: no-repeat;
}

.btn-blue--disabled {
  opacity: 0.5;
  cursor: default;
}
