.clock-hand {
  position: absolute;
  transform-origin: bottom center;
  left: 50%;
}

.clock-hand__second {
  height: 45%;
  top: 5%;
  width: 0.125rem;
  border-radius: 0;
  margin-left: -0.0625rem;
}

.clock-hand__minute {
  height: 40%;
  top: 10%;
  width: 0.375rem;
  border-radius: 0.625rem;
  margin-left: -0.1875rem;
}

.clock-hand__hour {
  height: 30%;
  top: 20%;
  width: 0.5rem;
  border-radius: 0.625rem;
  margin-left: -0.25rem;
  color: white;
}
