.digital-bottom-logo {
  height: 1rem;
  margin-top: 11.25rem;
}

@media only screen and (max-width: 64rem) {
  .digital-bottom-logo {
    position: absolute;
    top: calc(50% - 5.625rem);
    left: calc(50%);
    width: 12.5rem;
    height: 1rem;
  }
}
