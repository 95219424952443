.setup-globe {
  position: absolute;
  width: 34.375rem;
  height: 34.37r5em;
}

.globe {
  background-image: url('globe.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  width: 18.75rem;
  height: 18.75rem;
  position: absolute;
  top: 0.625rem;
  left: 4.375rem;
  z-index: 10;
}

.cloud1 {
  background-image: url('cloud1.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  width: 9.25rem;
  height: 4.6875rem;
  position: absolute;
  top: 2.1875rem;
  left: 3.125rem;
  z-index: 15;
}

.cloud2 {
  background-image: url('cloud2.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  width: 8.5rem;
  height: 5rem;
  position: absolute;
  top: 18rem;
  left: 1.625rem;
  z-index: 15;
}

.cloud3 {
  background-image: url('cloud3.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  width: 6.3125rem;
  height: 4.5rem;
  position: absolute;
  top: 20.3125rem;
  left: 18.125rem;
  z-index: 15;
}

.cloud4 {
  background-image: url('cloud4.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  width: 9.0625rem;
  height: 4.5625rem;
  position: absolute;
  top: 13.75rem;
  right: 1rem;
  z-index: 15;
}

.cloud5 {
  background-image: url('cloud5.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  width: 7.9375rem;
  height: 4.625rem;
  position: absolute;
  top: 0;
  right: 3.125rem;
  z-index: 5;
}
