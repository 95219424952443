.clock-notch {
  position: absolute;
  transform-origin: bottom center;
  left: 50%;
}

.clock-notch__large {
  height: 50%;
  top: 0;
  width: 0.375rem;
  margin-left: -0.1875rem;
}

.clock-notch__large::before {
  position: absolute;
  content: '';
  background-color: var(--notch-color);
  top: 0;
  left: 0;
  width: 0.375rem;
  height: 0.625rem;
  border-radius: 0.625rem;
}

.clock-notch__small {
  height: 50%;
  top: 0;
  width: 0.125rem;
  margin-left: -0.0625rem;
}

.clock-notch__small::before {
  position: absolute;
  content: '';
  background-color: var(--notch-color);
  top: 0;
  left: 0;
  width: 0.125rem;
  height: 0.5rem;
  border-radius: 0.625rem;
}

.clock-notch__hour {
  line-height: 1rem;
  width: 3rem;
  text-align: center;
  position: absolute;
  top: 2.5rem;
  left: -1.25rem;
  transform-origin: center center;
  font-weight: bold;
  font-size: 2.5rem;
}
